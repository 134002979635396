<template>
  <div>
    <b-card no-title>
      <b-card-title>
        {{ $t('card.title.add.areaCode') }}
      </b-card-title>
      <b-card-text>
        {{ $t('card.description.add.areaCode') }}
      </b-card-text>
    </b-card>
    <b-overlay :show="loading">
    <b-card-code>
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="3">
              <b-form-group
                  :label="$t('form.name')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.name').toLowerCase()"
                    rules="required"
                    vid="areaName"
                >
                  <b-form-input
                      v-model="formData.areaName"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('form.name').toLowerCase()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                  :label="$t('form.code')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.code').toLowerCase()"
                    rules="required"
                    vid="areaCode"
                >
                  <b-form-input
                      v-model="formData.areaCode"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('form.code').toLowerCase()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                  :label="$t('form.country')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.country').toLowerCase()"
                    rules="required"
                    vid="country"
                >
                  <v-select
                      v-model="formData.country"
                      label="title"
                      :options="countryOptions"
                      @input="countryChange"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                  :label="$t('form.county')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.county').toLowerCase()"
                    rules=""
                    vid="country"
                >
                  <v-select
                      v-model="formData.county"
                      label="title"
                      :clearable="true"
                      :options="countyOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                  :label="$t('form.content')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.content').toLowerCase()"
                    rules="required"
                    vid="content"
                >
                  <b-link
                      @click="$bvModal.show('bv-modal-post-help')"
                  >
                    <small class="text-primary cursor-pointer">* "Speciális jelölések" súgó</small>
                  </b-link>
                  <editor :value="formData.content" @input="contentUpdated"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit button -->
            <b-col class="mt-1">
              <b-button
                  variant="primary"
                  type="submit"
                  class="float-right"
                  @click.prevent="submitForm"
              >
                {{ $t('label.button.save') }}
              </b-button>
              <cancel-and-g-o-t-o-previous-page class="mr-2"/>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <help-modal/>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BCard, BCardTitle, BCardText, BForm, BRow, BCol, BButton, BFormCheckbox, BOverlay, BFormTextarea, BLink,
} from 'bootstrap-vue'
import {
  required, email,
} from '@validations'
import vSelect from 'vue-select'
import helpModal from '@/views/pages/_components/_modal/areaCode/help.vue'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import editor from '@/views/pages/_components/_helper/editor.vue'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BCardText,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BOverlay,
    BFormTextarea,
    BLink,
    helpModal,
    vSelect,
    cancelAndGOTOPreviousPage,
    editor,
  },
  data() {
    return {
      role: 'category',
      formData: {
        country: '',
        county: '',
        areaName: '',
        areaCode: '',
        content: '',
      },
      countryOptions: [],
      countyOptions: [],
      loading: false,
      required,
      email,
    }
  },
  created() {
    this.$store.dispatch('fetchCountriesForSelect').then(response => {
      this.countryOptions = response

      if(this.countryOptions) {
        Object.keys(this.countryOptions).forEach(key => {
          if(this.countryOptions[key].title === "Hungary") {
            this.formData.country = this.countryOptions[key]
          }
        })
      }
    }).finally(() => {
      this.countryChange()
    })
  },
  methods: {
    contentUpdated(value) {
      this.formData.content = value
    },
    countryChange() {
      this.loading = true
      this.$store.dispatch('fetchCountiesForSelect', {country_id: this.formData.country.value}).then(response => {
        this.countyOptions = response
        this.formData.county = ''
      }).finally(() => {
        this.loading = false
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loading = true
          // clone formData
          let fd = JSON.parse(JSON.stringify(this.formData))
          fd.country = fd.country.value
          fd.county = fd.county.value

          this.$store.dispatch('storeAreaCode', fd).then(response => {
            this.$helpers.showSuccessToast()

            this.$acl.canListPermission(this.role)
                ? this.$router.push({name: 'modify-areaCode', params: {id: response.data.entity.id}})
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
  },
}
</script>
