var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-title":""}},[_c('b-card-title',[_vm._v(" "+_vm._s(_vm.$t('card.title.add.areaCode'))+" ")]),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.$t('card.description.add.areaCode'))+" ")])],1),_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.name')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.name').toLowerCase(),"rules":"required","vid":"areaName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.name').toLowerCase()},model:{value:(_vm.formData.areaName),callback:function ($$v) {_vm.$set(_vm.formData, "areaName", $$v)},expression:"formData.areaName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.code')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.code').toLowerCase(),"rules":"required","vid":"areaCode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.code').toLowerCase()},model:{value:(_vm.formData.areaCode),callback:function ($$v) {_vm.$set(_vm.formData, "areaCode", $$v)},expression:"formData.areaCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.country')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.country').toLowerCase(),"rules":"required","vid":"country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"title","options":_vm.countryOptions},on:{"input":_vm.countryChange},model:{value:(_vm.formData.country),callback:function ($$v) {_vm.$set(_vm.formData, "country", $$v)},expression:"formData.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.county')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.county').toLowerCase(),"rules":"","vid":"country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"title","clearable":true,"options":_vm.countyOptions},model:{value:(_vm.formData.county),callback:function ($$v) {_vm.$set(_vm.formData, "county", $$v)},expression:"formData.county"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.content')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.content').toLowerCase(),"rules":"required","vid":"content"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-link',{on:{"click":function($event){return _vm.$bvModal.show('bv-modal-post-help')}}},[_c('small',{staticClass:"text-primary cursor-pointer"},[_vm._v("* \"Speciális jelölések\" súgó")])]),_c('editor',{attrs:{"value":_vm.formData.content},on:{"input":_vm.contentUpdated}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-1"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('label.button.save'))+" ")]),_c('cancel-and-g-o-t-o-previous-page',{staticClass:"mr-2"})],1)],1)],1)],1)],1),_c('help-modal')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }